<template>
  <div class="setting">
    <div class="info">
      <VanImage :src="myInfo.headimgurl" round width="1.6rem" height="1.6rem"/>
      <div class="middle">
        <div class="name">{{myInfo.username}}</div>
        <div class="account">账号：{{myInfo.mobile}}</div>
      </div>
      <!-- <div class="right">
        <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
      </div> -->
    </div>
    <CellGroup>
      <Cell title="积分" :value="myInfo.wallet.point"></Cell>
      <Cell title="余额" :value="myInfo.wallet.balance"></Cell>
      <Cell title="会员卡号" :value="myInfo.card.card_num"></Cell>
    </CellGroup>
    <CellGroup style="margin-top:10px">
      <!-- <Cell title="修改密码" to="password"></Cell> -->
      <!-- <Cell title="完善资料" to="saveInfo"></Cell> -->
      <Cell v-if="isWeixin_status" title="切换账号" @click="changeUser"></Cell>

    </CellGroup>
    <CellGroup style="margin-top:10px" v-if="isWeixin_status">
      <Cell title="重新绑定" to="bindPhone?type=101"></Cell>
    </CellGroup>
    <CellGroup v-if="isWeixin_status" style="margin-top:10px">
      <Cell title="清除缓存" @click="clearCache"></Cell>
    </CellGroup>
    <div v-if="!isWeixin_status" class="button" @click="outlogin">退出当前帐号</div>
  </div>
</template>
<script>
import { Image, CellGroup, Cell } from 'vant';
export default {
  components: {
    VanImage: Image,
    CellGroup,
    Cell
  },
  data() {
    return {
      myInfo: {
        wallet: {},
        card: {}
      },
      headimgurl: "",
      name: "",
    }
  },
  async created() {
    this.myInfo = await this.getUserInfo();
  },
  methods: {
    // 切换账号
    changeUser() {
      this.$router.push("/accountList");
    },
    clearCache() {
      this.$dialog.confirm({
          title: "确认清除缓存吗？",
          message: "温馨提示:清除缓存后请重新进入页面"
      }).then(() => {
        sessionStorage.clear();
      }).catch(() => {});
    },
    outlogin() {
      this.$dialog.confirm({
        title: '退出登陆',
      }).then(() => {
        let token = sessionStorage.getItem('access_token');
        try {
          if (token) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('userInfo');
            sessionStorage.clear();
            if (this.isWeixin_isWeixin_status) {
              // this.$router.go(0);
            } else {
              this.$router.push('/userlogin?appid=' + localStorage.getItem('app_id'));
            }
          }
        } catch (error) {
          console.log(error);
        }
      }).catch(() => {
        // on cancel
      });

    }
  }
};
</script>
<style lang="scss" scoped>
.setting {
  width: 100%;
  height: 100vh;
  background-color: #f4f4f4;
  font-family:PingFang SC;
  padding-bottom: 1px;
  min-height: 100vh;
  position: fixed;
  .info {
    width: 100%;
    height: 90px;
    margin: 8px 0;
    background-color: #fff;
    padding: 12px 12px;
    display: flex;
    VanImage {
      width: 46px;
      height: 46px;
      border-radius: 23px;
      margin-right: 8px;
    }
    .middle{
      flex:1;
      padding-top: 6px;
      padding-left: 10px;
      .name{
        font-size: 14px;
        color:#333;
        margin-bottom:7px;
      }
      .account{
        font-size: 11px;
        color: #999;
      }
    }
    .right{
      width:7px;
      line-height: 46px;
    }
  }
  .button{
    width:100%;
    height: 45px;
    margin-top: 12px;
    color:#333;
    font-size: 16px;
    font-weight:500;
    text-align: center;
    background-color: #fff;
    line-height: 45px;
    position: fixed;
    left: 0;
    bottom: 20px;
  }
}
</style>
